import React, { useState,useEffect } from 'react';


import styles from './weButton.module.css';

/**
 * style objects
 */
const basicStyle = {
    backgroundColor: "#01AFDF",
    color: "snow"
}



const WEButton = ({ children,type,onClick,disabled=false,btnColor = '#01AFDF',style,...props}) => {
    const [hover, setHover] = useState(false);            
    const toggleHover = (e) => {
        if (disabled) return;
                    
        if(hover){
            e.target.classList.remove(styles.hover);
        }else{
            e.target.classList.add(styles.hover);
        }
        setHover(!hover);
    }

    document.documentElement.style.setProperty('--psuedo-bg-color',btnColor);
    document.documentElement.style.setProperty('--psuedo-bg-color-dark',btnColor);
    document.documentElement.style.setProperty('--psuedo-hover-transform','scaleX(0)');
    

    let btnStyle;
    

    const defaultStyle = {
        backgroundColor: btnColor ? btnColor : 'transparent',
        color: "snow"
    }
    const outlineType = {
        backgroundColor: 'transparent',
        border: `2px solid ${btnColor}`
    }
    const disabledStyle = {
        cursor: "default",
        opacity: '0.4',
        transform: "scale(1)",
        boxShadow: "0"
    }

    

    switch (type) {
        case 'outline':
            btnStyle = outlineType;
            break;
    
        default:
            btnStyle = defaultStyle;
            break;
    }

    return (
        <button
            disabled={disabled}
            style={
                disabled ? 
                {...defaultStyle,...btnStyle,...disabledStyle,...style} : 
                {...defaultStyle,...btnStyle,...style}
            }
            className={styles.button}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            onClick={!disabled ? onClick : () => {}}            
            {...props}
        >
            {children}
        </button>
    );
};

export default WEButton;