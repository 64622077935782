
/**
 * An object contains a list of the 50 US states
 * full name
 */
export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
]

/**
 * Method that capatilzes only the first character
 * of the first word of the passed string
 * 
 * @param {String} str 
 * @returns {String}
 */
export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Method that capatilzes the first character
 * of the all words in the passed string
 * 
 * @param {String} str 
 * @returns {String}
 */
export const capitalizeEachFirstLetter = (str) => {
    let strArr = str.split(" ");
    strArr.forEach(str => {
        capitalizeFirstLetter(str);
    });
    return strArr.join(" ");
}

/**
 * Method accepts a string, char,int,float and truncates
 * the value to 2 decimal points and returns a string
 * formatted with a US dollar sign ($) EX: $x.xx
 * 
 * @param {*} value 
 * @returns {String} $x.xx
 */
export const currencyFormatter = value => {
    let val = parseFloat(value).toFixed(2)
    return `$${val}`;
}

/**
 * Method accepts a string, char,int,float and truncates
 * the value to 2 decimal points and returns a string
 * formatted with the locale currrency symbol and seperator
 * 
 * @param {*} value 
 * @returns {String} $x.xx
 */
export const currencyFormatterLocale = value => {
    let val = parseFloat(value).toFixed(2)
    return `$${val}`;
}


/**
 * 
 * @param {*} formData 
 * @returns 
*/
export const seqNumbersArr = () => {
    return Array.from(Array(50).keys());
}


/**
 * Creates a new FormData() object and appends
 * the items from the @param formData object 
 * and then returns the FormData object; Used
 * when creating a fetch request with params
 * 
 * @param {String} action 
 * @param {Object} formData 
 * @returns {Object} FormData object
 */
 export const createFormDataObj = (formData) => {
    const fd = new FormData();  
    for (const key in formData) {
        if (Object.hasOwnProperty.call(formData, key)) {
            const item = formData[key];
            fd.append(key,item);
        }
    }
    return fd;
}


export const apiDataFetch = (_url,_options,_responseType = "json") => {
    

    async function fetchData(_url,_options) {
        try {
            const res = await fetch(_url, _options);
            const json = await res.json();
            return json;
        } catch (e) {
            console.log(e);
            return e;            
        }        
    }
    return fetchData(_url,_options);
}



export const dateTool = {
    todaysDate(withTime = true){
        const dt = new Date();
        let dateStr = `${dt.getFullYear()}-${this.padNumber(dt.getMonth() + 1)}-${this.padNumber(dt.getDate())}`;
        let timeStr = `${this.padNumber(dt.getUTCHours())}:${this.padNumber(dt.getMinutes())}:${this.padNumber(dt.getSeconds())}`
        let retStr = withTime ? `${dateStr} ${timeStr}` : dateStr;
        console.log(retStr);
        return retStr;
    },
    nextMonthDate(date,withTime=true){

        const dt = new Date(date);
        
        let year = dt.getFullYear();
        let month = ((dt.getMonth() + 1) == 12) ? '01' : this.padNumber(dt.getMonth() + 2);
        let day = this.padNumber(dt.getDate());
        let dateStr = `${year}-${month}-${day}`;
        let retStr = withTime ? `${dateStr} 04:20:01` : dateStr;
        
        return retStr;
    },
    padNumber(_num){
        let tmp = ('0' + _num).slice(-2);
        return tmp;
    }
}



export const softwareDownloadViewData = {
    integrator: {
        overview: `Download and install the SMSIntegrator service and configure it with an Agent associated with the store's organization. This service initiates and maintains connection to the GLDS Tools cloud services`,
        fileLink: 'http://integrator.gldstools.com/SMS%20Integrator%20Setup.msi',
        title: 'SMS Integrator Service',
        subtitle: 'Download and Documentation',
        versions: [
            {release:"0.0.1",fileLink: 'http://integrator.gldstools.com/SMS%20Integrator%20Setup.msi',fileName:"SMSIntegratorSetup.msi"},
            {release:"0.0.2",fileLink: 'http://integrator.gldstools.com/SMS%20Integrator%20Setup.msi',fileName:"SMSIntegratorSetup.msi"},
            {release:"0.0.3",fileLink: 'http://integrator.gldstools.com/SMS%20Integrator%20Setup.msi',fileName:"SMSIntegratorSetup.msi"},
            {release:"0.0.4",fileLink: 'http://integrator.gldstools.com/SMS%20Integrator%20Setup.msi',fileName:"SMSIntegratorSetup.msi"},
            {release:"0.0.5",fileLink: 'http://integrator.gldstools.com/SMS%20Integrator%20Setup.msi',fileName:"SMSIntegratorSetup.msi",currentRelease:true}
        ],
        process: []         
    }
}